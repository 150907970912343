#components-layout-demo-fixed-sider .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}
.site-layout .site-layout-background {
  background: #fff;
}

.navbarLogo {
  background-color: lightgrey;
  display: flex;
  height: 65px;
  align-items: center;
  justify-content: center;
}
