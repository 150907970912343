.panelButtons {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    justify-content: flex-end; 
}

.chips {
    height: 30px;
    font-size: 16px;
    background-color: white;
    padding: 0px 10px 0px 10px;
    color : tomato;
    border: 1.8px solid tomato;
    border-radius: 20px;
    justify-content: center;
    margin: 20px 0px 0px 10px;
    cursor: pointer;
    text-transform: capitalize;
}