.courseScreenContainer {
    /* background-color: cadetblue; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.courseHeader {
    padding: 0;
    text-align: center;
    font-size: 30px;
    font-weight: 300px;
    background: #fff;
}

.courseContent {
    margin: 24px 16px 0;
    overflow: initial;
    background: #fff;
    border-radius: 10px;
    box-shadow: 2px 1px 10px lightgrey;
}

.courseScreenTop {
    /* background-color: rebeccapurple; */
    width: 90%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

/* .courseScreenTopBreadcrumbContainer {
    background-color: red;
} */

.courseScreenMiddle {
    /* background-color: blue; */
    width: 90%;
    display: grid;
    height: 80vh;
    overflow: scroll;
    grid-template-columns: auto auto auto auto;
    /* align-items: center; */
    /* justify-content: space-evenly;  */
}

.courseTableContainer {
    /* background-color: rebeccapurple; */
    width: 100%;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    overflow: auto;
    /* flex-direction: column; */
}

.courseSearchContainer {
    padding: 1% 5% 1% 5%;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.addBtn {
    color: white;
    justify-content: center;
    display: flex;
    align-items: center;
}

.input {
    width: 100%;
    border-color: lightgrey;
    border-width: 1px;
    height: 40px;
    padding-left: 5px;
}

.textArea {
    height: 60px;
    width: 100%;
    border-color: lightgrey;
    border-width: 1px;
    padding-left: 5px;
}

.label {
    margin-top: 15px;
    margin-bottom: 2px;
}

.selectLang {
    width: 60%;
    margin-right: 20px;
    margin-left: 20px;
}

.switch {
    width: 60px;
    margin-right: 20px;
    margin-left: 20px;
}