.videosScreenContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.videosScreenTop {
    /* background-color: rebeccapurple; */
    width: 90%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.videosScreenTopBreadcrumbContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* background-color: red; */
}

.videosScreenMiddle {
    height: 80vh;
    overflow: scroll;
    width: 100%;
    padding: 1% 5% 1% 5%;
}


.videoSearchContainer {
    padding: 1% 0% 1% 5%;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: flex-end;    
}

tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}

.transcriptIcon {
    color: tomato;
    font-weight: bold;
    font-size: 18px;
}