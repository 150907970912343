.momentContainer {
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.momentContainerTop {
    /* background-color: #419641; */
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.momentContainerBottom {
    /* background-color: blue; */
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.momentKeyValue {
    /* background-color: rebeccapurple; */
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.momentKeyText {
    /* background-color: red; */
    padding-right: 5px;
    font-weight: 600;
    font-size: 16px;
}

.momentValueText {
    /* background-color: red; */
    padding-right: 5px;
    font-size: 15px;
}

.ant-picker-now-btn {
    display: none;
}