.questionBankScreenContainer {
    /* background-color: #777; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.questionBankTop {
    /* background-color: rebeccapurple; */
    width: 90%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.questionBankTopBreadcrumbContainer {
    /* background-color: red; */
}
.questionBankTopButtonContainer {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    /* background-color: rebeccapurple; */
}

.questionBankMiddle {
    /* background-color: cadetblue; */
    padding: 0px 15px 0px 15px;
    width: 100%;

    height: 80vh;
    overflow: scroll;
    
    display: flex;
    /* align-items: center; */
    justify-content: center;
}

.dynamic-delete-button {
    position: relative;
    margin: 0 10px;
    cursor: pointer;
    transition: all 0.3s;
    box-shadow: 1px 2px 2px lightgray;
}
.dynamic-delete-button:hover {
    color: #777;
}
.dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
}