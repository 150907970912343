.libraryScreenContainer {
    /* background-color: cadetblue; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.libraryScreenTop {
    /* background-color: rebeccapurple; */
    width: 90%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.libraryScreenTopBreadcrumbContainer {
    /* background-color: red; */
}

.libraryScreenMiddle {
    /* background-color: blue; */
    padding: 0px 15px 0px 15px;
    width: 100%;
    height: 80vh;
    display: flex;
    /* align-items: center; */
    justify-content: center;
}
.library-content{
    overflow: scroll;
}

.media-filter-container{
    position: sticky;
}