@import url("https://fonts.googleapis.com/css2?family=Merriweather&family=Montserrat&display=swap");

:root {
  --sidebar-header-background-color: #1f363d;
  --sidebar-color: #4da596;
  --button-color: #17a2b8;
  --sidebar-text-color: #1479ff;
  --primary-background-color: #ecf2f6;
  --accent-color-primary: #0cbeda;
  --primary-font: "Merriweather", serif;
  --secondary-font: "Montserrat", sans-serif;
}

body {
  background-color: #ecf2f6 !important;
  background-image: none;
}

.ag-root-wrapper {
  border-radius: 10px;
}

.default-btn {
  background-color: var(--button-color);
  border-radius: 5px;
  color: white;
  /* padding: 5px ; */
  border: 1px solid transparent;
  outline: none;
}

.default-btn:hover {
  background-color: white;
  color: var(--button-color);
  border: 1px solid var(--button-color);
}

.ant-menu-item-selected{
  background-color: black;
}
/*---------------------------------- LOGIN SCREEN ---------------------------------- */

.login-div {
  width: 50vw;
  height: 60vh;
  background-color: white;
  margin: auto;
  margin-top: 15vh;
  border-radius: 25px;
  box-shadow: 9px 12px 20px -1px rgba(0, 0, 0, 0.27);
  -webkit-box-shadow: 9px 12px 20px -1px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 9px 12px 20px -1px rgba(0, 0, 0, 0.27);
  padding: 0 45px;
}

.login-div > .login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.login-form > div {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 80%;
}

.login-form > div > input {
  border: none;
  border-bottom: 2px solid #b0bdbe;
  outline: none;
}

.login-form > div > input:focus {
  border-bottom: 2px solid #25dabb;
}

.login-form > button {
  background-color: var(--button-color);
  width: 80%;
  border-radius: 5px;
  color: white;
  padding: 5px 0;
  border: none;
  outline: none;
}

.login-form > button:hover {
  border: 2px solid var(--button-color);
  color: var(--button-color);
  background-color: #1f363d;
}

.alerts {
  position: fixed;
  top: 32px;
  margin: auto;
  display: flex;
  width: 100%;
  justify-content: center;
  /* align-items: center; */
  left: 0;
  z-index: 2024;
}

.alert-close-btn {
  height: 49px;
  background: #abdcb7;
  border: none;
  color: red;
  font-size: 2rem;
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
}


.btn-circle{
  border-radius: 100%;
  width: 70%;
height: 40px;
}

.logout-btn-container{
    color: white;
    /* height: 35px; */
    cursor: pointer;
    /* padding: 0 10px; */
    line-height: 35px;
    box-shadow: 1px 1px 1px #aaa;
    background: var(--themeColor) !important;
    position: absolute;
bottom: 20px;
left: 30%;
}

.forgot-password, .reset-password
{
  text-align: center;
margin-top: 3%;
color: var(--button-color);
cursor: pointer;
}

.forgot-password:hover, .reset-password:hover{
  text-decoration: solid;
  color: var(--accent-color-primary);
}

.reset-password:disabled{
  text-decoration: solid;
  background-color: var(--sidebar-header-background-color) !important;
  cursor: not-allowed;

}

#go-home{
  width:max-content;
  height: fit-content;
  margin: 2%;
  font-size: small;
  background-color: #a2777717 !important;
}
#go-home>span{
 color: #4c6e8c;
}

.note{
  font-size: small;
  color: var(--button-color)
}