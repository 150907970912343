.header {
    padding: 15px 30px 5px 30px;
    margin: 20px;
    min-height: 30px;
    justify-content: space-between;
    display: flex;
    flex-direction: row,
}
.libraryScreenMiddle {
    /* background-color: blue; */
    padding: 0px 15px 0px 15px;
    width: 100%;
    height: 80vh;
    display: flex;
    /* align-items: center; */
    justify-content: center;
}