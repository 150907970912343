@import url(https://fonts.googleapis.com/css2?family=Merriweather&family=Montserrat&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
  color: black;
  background-color: white;
}
:root {
  --sidebar-header-background-color: #1f363d;
  --sidebar-color: #4da596;
  --button-color: #17a2b8;
  --sidebar-text-color: #1479ff;
  --primary-background-color: #ecf2f6;
  --accent-color-primary: #0cbeda;
  --primary-font: "Merriweather", serif;
  --secondary-font: "Montserrat", sans-serif;
}

body {
  background-color: #ecf2f6 !important;
  background-image: none;
}

.ag-root-wrapper {
  border-radius: 10px;
}

.default-btn {
  background-color: #17a2b8;
  background-color: var(--button-color);
  border-radius: 5px;
  color: white;
  /* padding: 5px ; */
  border: 1px solid transparent;
  outline: none;
}

.default-btn:hover {
  background-color: white;
  color: #17a2b8;
  color: var(--button-color);
  border: 1px solid #17a2b8;
  border: 1px solid var(--button-color);
}

.ant-menu-item-selected{
  background-color: black;
}
/*---------------------------------- LOGIN SCREEN ---------------------------------- */

.login-div {
  width: 50vw;
  height: 60vh;
  background-color: white;
  margin: auto;
  margin-top: 15vh;
  border-radius: 25px;
  box-shadow: 9px 12px 20px -1px rgba(0, 0, 0, 0.27);
  -webkit-box-shadow: 9px 12px 20px -1px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 9px 12px 20px -1px rgba(0, 0, 0, 0.27);
  padding: 0 45px;
}

.login-div > .login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.login-form > div {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 80%;
}

.login-form > div > input {
  border: none;
  border-bottom: 2px solid #b0bdbe;
  outline: none;
}

.login-form > div > input:focus {
  border-bottom: 2px solid #25dabb;
}

.login-form > button {
  background-color: #17a2b8;
  background-color: var(--button-color);
  width: 80%;
  border-radius: 5px;
  color: white;
  padding: 5px 0;
  border: none;
  outline: none;
}

.login-form > button:hover {
  border: 2px solid #17a2b8;
  border: 2px solid var(--button-color);
  color: #17a2b8;
  color: var(--button-color);
  background-color: #1f363d;
}

.alerts {
  position: fixed;
  top: 32px;
  margin: auto;
  display: flex;
  width: 100%;
  justify-content: center;
  /* align-items: center; */
  left: 0;
  z-index: 2024;
}

.alert-close-btn {
  height: 49px;
  background: #abdcb7;
  border: none;
  color: red;
  font-size: 2rem;
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
}


.btn-circle{
  border-radius: 100%;
  width: 70%;
height: 40px;
}

.logout-btn-container{
    color: white;
    /* height: 35px; */
    cursor: pointer;
    /* padding: 0 10px; */
    line-height: 35px;
    box-shadow: 1px 1px 1px #aaa;
    background: var(--themeColor) !important;
    position: absolute;
bottom: 20px;
left: 30%;
}

.forgot-password, .reset-password
{
  text-align: center;
margin-top: 3%;
color: #17a2b8;
color: var(--button-color);
cursor: pointer;
}

.forgot-password:hover, .reset-password:hover{
  -webkit-text-decoration: solid;
          text-decoration: solid;
  color: #0cbeda;
  color: var(--accent-color-primary);
}

.reset-password:disabled{
  -webkit-text-decoration: solid;
          text-decoration: solid;
  background-color: #1f363d !important;
  background-color: var(--sidebar-header-background-color) !important;
  cursor: not-allowed;

}

#go-home{
  width:-webkit-max-content;
  width:max-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 2%;
  font-size: small;
  background-color: #a2777717 !important;
}
#go-home>span{
 color: #4c6e8c;
}

.note{
  font-size: small;
  color: #17a2b8;
  color: var(--button-color)
}
#components-layout-demo-fixed-sider .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}
.site-layout .site-layout-background {
  background: #fff;
}

.navbarLogo {
  background-color: lightgrey;
  display: flex;
  height: 65px;
  align-items: center;
  justify-content: center;
}

.questionBankScreenContainer {
    /* background-color: #777; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.questionBankTop {
    /* background-color: rebeccapurple; */
    width: 90%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.questionBankTopBreadcrumbContainer {
    /* background-color: red; */
}
.questionBankTopButtonContainer {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    /* background-color: rebeccapurple; */
}

.questionBankMiddle {
    /* background-color: cadetblue; */
    padding: 0px 15px 0px 15px;
    width: 100%;

    height: 80vh;
    overflow: scroll;
    
    display: flex;
    /* align-items: center; */
    justify-content: center;
}

.dynamic-delete-button {
    position: relative;
    margin: 0 10px;
    cursor: pointer;
    transition: all 0.3s;
    box-shadow: 1px 2px 2px lightgray;
}
.dynamic-delete-button:hover {
    color: #777;
}
.dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
}
.questionTableContainer {
    /* background-color: rebeccapurple; */
    width: 100%;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    overflow: auto;
    /* flex-direction: column; */
}

.tableSearchContainer {
    padding: 1% 5% 1% 5%;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: flex-end;    
}

.confirmationModalText {
    /* background-color: red; */
    margin-bottom: 10px;
}

.questionBankTable {
    width: auto;
}

.questionTableContainer {
    /* background-color: rebeccapurple; */
    width: 100%;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    overflow: auto;
    /* flex-direction: column; */
}

.tableSearchContainer {
    padding: 1% 5% 1% 5%;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: flex-end;    
}

.confirmationModalText {
    /* background-color: red; */
    margin-bottom: 10px;
}

.questionBankTable {
    width: auto;
}
.topicsScreenContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.topicsScreenTop {
    /* background-color: rebeccapurple; */
    width: 90%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.topicsScreenTopBreadcrumbContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* background-color: red; */
}

.topicsListContainer {
    /* background-color: rebeccapurple; */
    width: 90%;
    height: 80vh;
    overflow: scroll;
    padding: 1% 5% 1% 5%;
}
.videosScreenContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.videosScreenTop {
    /* background-color: rebeccapurple; */
    width: 90%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.videosScreenTopBreadcrumbContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* background-color: red; */
}

.videosScreenMiddle {
    height: 80vh;
    overflow: scroll;
    width: 100%;
    padding: 1% 5% 1% 5%;
}


.videoSearchContainer {
    padding: 1% 0% 1% 5%;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: flex-end;    
}

tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}

.transcriptIcon {
    color: tomato;
    font-weight: bold;
    font-size: 18px;
}
.momentContainer {
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.momentContainerTop {
    /* background-color: #419641; */
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.momentContainerBottom {
    /* background-color: blue; */
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.momentKeyValue {
    /* background-color: rebeccapurple; */
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.momentKeyText {
    /* background-color: red; */
    padding-right: 5px;
    font-weight: 600;
    font-size: 16px;
}

.momentValueText {
    /* background-color: red; */
    padding-right: 5px;
    font-size: 15px;
}

.ant-picker-now-btn {
    display: none;
}
.libraryScreenContainer {
    /* background-color: cadetblue; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.libraryScreenTop {
    /* background-color: rebeccapurple; */
    width: 90%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.libraryScreenTopBreadcrumbContainer {
    /* background-color: red; */
}

.libraryScreenMiddle {
    /* background-color: blue; */
    padding: 0px 15px 0px 15px;
    width: 100%;
    height: 80vh;
    display: flex;
    /* align-items: center; */
    justify-content: center;
}
.library-content{
    overflow: scroll;
}

.media-filter-container{
    position: -webkit-sticky;
    position: sticky;
}
.libraryTableContainer {
    /* background-color: rebeccapurple; */
    width: 100%;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    overflow: auto;
    /* flex-direction: column; */
}

.tableSearchContainer {
    padding: 1% 5% 1% 5%;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: flex-end;    
}


.fileLibraryTable {
    width: auto;
}
.userListContainer {
    /* background-color: rebeccapurple; */
    width: 100%;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    overflow: auto;
    /* flex-direction: column; */
}

.userSearchContainer {
    padding: 1% 5% 1% 5%;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: flex-end;    
}
.moduleContainer{
    background-color: 'white';
    align-self: 'center';
    width: '100%';
    justify-content: 'center';
    padding-left: 50;
    padding-right: 50;
}

.moduleBlock{
    display: 'flex';
    background-color: '#f7f7f7';
    height: 40;
    flex-direction: 'row';
}
.panelButtons {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    justify-content: flex-end; 
}

.chips {
    height: 30px;
    font-size: 16px;
    background-color: white;
    padding: 0px 10px 0px 10px;
    color : tomato;
    border: 1.8px solid tomato;
    border-radius: 20px;
    justify-content: center;
    margin: 20px 0px 0px 10px;
    cursor: pointer;
    text-transform: capitalize;
}
.ant-select-selection--multiple
{
   white-space: nowrap;
   height: 100px;
   overflow: auto
}

.header {
    padding: 15px 30px 5px 30px;
    margin: 20px;
    min-height: 30px;
    justify-content: space-between;
    display: flex;
    flex-direction: row,
}
.content{
    padding: 24px;
    margin: 20px;
    min-height: 500px;
    border-radius: 20px;
}

.align-right{
    display: flex;
    justify-content: flex-end;
}

.edit-btn{
    background-color: tomato;
    height: 35px;
    width: 35px;
    border-radius: 15px;
    margin: auto;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-self: center;
}
.search{
    width: 300px;
    margin-bottom: 20px; 
}
.synonymCount{
    text-transform: capitalize;
     cursor: pointer;
}
.courseScreenContainer {
    /* background-color: cadetblue; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.courseHeader {
    padding: 0;
    text-align: center;
    font-size: 30px;
    font-weight: 300px;
    background: #fff;
}

.courseContent {
    margin: 24px 16px 0;
    overflow: initial;
    background: #fff;
    border-radius: 10px;
    box-shadow: 2px 1px 10px lightgrey;
}

.courseScreenTop {
    /* background-color: rebeccapurple; */
    width: 90%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

/* .courseScreenTopBreadcrumbContainer {
    background-color: red;
} */

.courseScreenMiddle {
    /* background-color: blue; */
    width: 90%;
    display: grid;
    height: 80vh;
    overflow: scroll;
    grid-template-columns: auto auto auto auto;
    /* align-items: center; */
    /* justify-content: space-evenly;  */
}

.courseTableContainer {
    /* background-color: rebeccapurple; */
    width: 100%;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    overflow: auto;
    /* flex-direction: column; */
}

.courseSearchContainer {
    padding: 1% 5% 1% 5%;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.addBtn {
    color: white;
    justify-content: center;
    display: flex;
    align-items: center;
}

.input {
    width: 100%;
    border-color: lightgrey;
    border-width: 1px;
    height: 40px;
    padding-left: 5px;
}

.textArea {
    height: 60px;
    width: 100%;
    border-color: lightgrey;
    border-width: 1px;
    padding-left: 5px;
}

.label {
    margin-top: 15px;
    margin-bottom: 2px;
}

.selectLang {
    width: 60%;
    margin-right: 20px;
    margin-left: 20px;
}

.switch {
    width: 60px;
    margin-right: 20px;
    margin-left: 20px;
}
.header {
    padding: 15px 30px 5px 30px;
    margin: 20px;
    min-height: 30px;
    justify-content: space-between;
    display: flex;
    flex-direction: row,
}
.libraryScreenMiddle {
    /* background-color: blue; */
    padding: 0px 15px 0px 15px;
    width: 100%;
    height: 80vh;
    display: flex;
    /* align-items: center; */
    justify-content: center;
}
.questionTableContainer {
    /* background-color: rebeccapurple; */
    width: 100%;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    overflow: auto;
    /* flex-direction: column; */
}

.tableSearchContainer {
    padding: 1% 5% 1% 5%;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: flex-end;    
}

.confirmationModalText {
    /* background-color: red; */
    margin-bottom: 10px;
}

.questionBankTable {
    width: auto;
}
/* .quoteTitleText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: "----";
} */
/* .questionBankTable .ant-table-tbody > tr > td{
    white-space: nowrap;
    overflow: hidden;
}  */
.directoryScreenContainer {
    /* background-color: cadetblue; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.directoryHeader {
    padding: 0;
    text-align: center;
    font-size: 30px;
    font-weight: 300px;
    background: #fff;
}

.directoryContent {
    margin: 24px 16px 0;
    overflow: initial;
    background: #fff;
    border-radius: 10px;
    box-shadow: 2px 1px 10px lightgrey;
}

.directoryScreenTop {
    /* background-color: rebeccapurple; */
    width: 90%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.directoryScreenMiddle {
    /* background-color: blue; */
    width: 90%;
    display: grid;
    height: 80vh;
    overflow: scroll;
    grid-template-columns: auto auto auto auto;
    /* align-items: center; */
    /* justify-content: space-evenly;  */
}

.directoryTableContainer {
    /* background-color: rebeccapurple; */
    width: 100%;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    overflow: auto;
    /* flex-direction: column; */
}

.directorySearchContainer {
    padding: 1% 5% 1% 5%;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.addBtn {
    color: white;
    justify-content: center;
    display: flex;
    align-items: center;
}

.input {
    width: 100%;
    border-color: lightgrey;
    border-width: 1px;
    height: 30px,
}

.txtArea {
    height: 60px !important;
}

.label {
    margin-top: 15px;
    margin-bottom: 5px;
}
   .questionTableContainer {
    width: 100%;
    overflow: auto;
   }

   .tableSearchContainer {
    padding: 1% 5% 1% 5%;
    display: flex;
    align-items: center;
    justify-content: flex-end;    
   }

  .confirmationModalText {
    margin-bottom: 10px;
   }

   .questionBankTable {
    width: auto;
    }

   .event-box {
    display: flex;
    grid-gap:12px;
    gap:12px;
    }

.textArea {
    height: 60px;
    width: 100%;
    border-color: lightgrey;
    border-width: 1px;
    padding-left: 5px;
}
.ant-form-item-label > label{
    text-transform: capitalize !important;
} 
.link-input{
    width: 75% !important;
    color:blue !important;
}
.button-remove-thumbnail{
    margin-left: 2vw;
    margin-top: 27vh;
}
.event-description{
    cursor: 'pointer'; 
    color : blue;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; 
}
.questionTableContainer {
    width: 100%;
    overflow: auto;
   }

   .tableSearchContainer {
    padding: 1% 5% 1% 5%;
    display: flex;
    align-items: center;
    justify-content: flex-end;    
   }

  .confirmationModalText {
    margin-bottom: 10px;
   }

   .questionBankTable {
    width: auto;
    }

   .event-box {
    display: flex;
    grid-gap:12px;
    gap:12px;
    }

.textArea {
    height: 60px;
    width: 100%;
    border-color: lightgrey;
    border-width: 1px;
    padding-left: 5px;
}
.ant-form-item-label > label{
    text-transform: capitalize !important;
} 
.link-input{
    width: 75% !important;
    color:blue !important;
}
.button-remove-thumbnail{
    margin-left: 2vw;
    margin-top: 27vh;
}
.event-description{
    cursor: 'pointer'; 
    color : blue;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; 
}
.header {
    padding: 15px 30px 5px 30px;
    margin: 20px;
    min-height: 30px;
    justify-content: space-between;
    display: flex;
    flex-direction: row,
}
.libraryScreenMiddle {
    /* background-color: blue; */
    padding: 0px 15px 0px 15px;
    width: 100%;
    height: 80vh;
    display: flex;
    /* align-items: center; */
    justify-content: center;
}
.questionTableContainer {
    /* background-color: rebeccapurple; */
    width: 100%;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    overflow: auto;
    /* flex-direction: column; */
}

.tableSearchContainer {
    padding: 1% 5% 1% 5%;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: flex-end;    
}

.confirmationModalText {
    /* background-color: red; */
    margin-bottom: 10px;
}

.questionBankTable {
    width: auto;
}
/* .quoteTitleText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: "----";
} */
/* .questionBankTable .ant-table-tbody > tr > td{
    white-space: nowrap;
    overflow: hidden;
}  */
