.ant-select-selection--multiple
{
   white-space: nowrap;
   height: 100px;
   overflow: auto
}

.header {
    padding: 15px 30px 5px 30px;
    margin: 20px;
    min-height: 30px;
    justify-content: space-between;
    display: flex;
    flex-direction: row,
}
.content{
    padding: 24px;
    margin: 20px;
    min-height: 500px;
    border-radius: 20px;
}

.align-right{
    display: flex;
    justify-content: flex-end;
}

.edit-btn{
    background-color: tomato;
    height: 35px;
    width: 35px;
    border-radius: 15px;
    margin: auto;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-self: center;
}
.search{
    width: 300px;
    margin-bottom: 20px; 
}
.synonymCount{
    text-transform: capitalize;
     cursor: pointer;
}