.moduleContainer{
    background-color: 'white';
    align-self: 'center';
    width: '100%';
    justify-content: 'center';
    padding-left: 50;
    padding-right: 50;
}

.moduleBlock{
    display: 'flex';
    background-color: '#f7f7f7';
    height: 40;
    flex-direction: 'row';
}