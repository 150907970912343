.topicsScreenContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.topicsScreenTop {
    /* background-color: rebeccapurple; */
    width: 90%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.topicsScreenTopBreadcrumbContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* background-color: red; */
}

.topicsListContainer {
    /* background-color: rebeccapurple; */
    width: 90%;
    height: 80vh;
    overflow: scroll;
    padding: 1% 5% 1% 5%;
}