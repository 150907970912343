.questionTableContainer {
    width: 100%;
    overflow: auto;
   }

   .tableSearchContainer {
    padding: 1% 5% 1% 5%;
    display: flex;
    align-items: center;
    justify-content: flex-end;    
   }

  .confirmationModalText {
    margin-bottom: 10px;
   }

   .questionBankTable {
    width: auto;
    }

   .event-box {
    display: flex;
    gap:12px;
    }

.textArea {
    height: 60px;
    width: 100%;
    border-color: lightgrey;
    border-width: 1px;
    padding-left: 5px;
}
.ant-form-item-label > label{
    text-transform: capitalize !important;
} 
.link-input{
    width: 75% !important;
    color:blue !important;
}
.button-remove-thumbnail{
    margin-left: 2vw;
    margin-top: 27vh;
}
.event-description{
    cursor: 'pointer'; 
    color : blue;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; 
}