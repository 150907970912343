.userListContainer {
    /* background-color: rebeccapurple; */
    width: 100%;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    overflow: auto;
    /* flex-direction: column; */
}

.userSearchContainer {
    padding: 1% 5% 1% 5%;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: flex-end;    
}